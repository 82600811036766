import PhotoSwipe from "photoswipe"
import PhotoSwipeUI from "photoswipe/dist/photoswipe-ui-default"
import { Controller } from "stimulus"

import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

const DEFAULT_OPTIONS = {
  index: 0,
  history: false,
  closeOnScroll: false
}

const indexForSlideId = (slideId, slides) => {
  if (slideId === null || slideId === undefined) return

  return slides.findIndex(slide => slide.id == slideId)
}

export default class extends Controller {
  static targets = ["slide"]

  get slideshowElement() {
    this._slideshowElement =
      this._slideshowElement || document.getElementById("slideshow")
    return this._slideshowElement
  }

  showSlide(event) {
    event.preventDefault()

    const slideId = event.currentTarget.dataset.slideId
    const slides = this.collectSlides()

    const slideshow = new PhotoSwipe(this.slideshowElement, PhotoSwipeUI, slides, {
      ...DEFAULT_OPTIONS,
      index: indexForSlideId(slideId, slides) || 0
    })

    slideshow.init()
  }

  collectSlides() {
    return this.slideTargets.map(slideTarget => {
      const props = JSON.parse(slideTarget.dataset.slideProps)

      if (!props.title) {
        props.title = slideTarget.innerHTML
      }

      return props
    })
  }
}
