// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Turbolinks from "turbolinks"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "../styles/index.scss"

Turbolinks.start()

const application = Application.start()
const context = require.context("./../controllers/application", false, /\.(js|ts)x?$/)
const sharedContext = require.context("./../controllers/shared", false, /\.(js|ts)x?$/)

application.load(definitionsFromContext(context))
application.load(definitionsFromContext(sharedContext))

document.addEventListener("turbolinks:visit", function () {
  if (window.posthog) {
    window.posthog.capture("$pageview")
  }
})
